import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ApiClient from '../../services'
import Constant from '../../config/booking'

/**
 * Vaidate equipment option with selected maid
 * and compose necessary computed/methods that related with equipment
 */

export const equipmentMixin = {
  data: function () {
    return {
      priceOptions: []
    }
  },
  async mounted() {
    try {
      const results = await Promise.all([
        ApiClient.getPriceOptions()
      ])
      this.priceOptions = get(results, '[0].data', []).map(option => ({
        text: option.type  === 'EQUIPMENT' ? 'dont_have_equipment' : '',
        value: option.price,
        bring_equipment: option.type === 'EQUIPMENT'
      }))
    } catch (error) {
      console.warn("ERROR:", error)
    }
  },
  methods: {
    getDefaultEquipmentOption(bookingDetail = {}) {
      console.log("getDefaultEquipmentOption", bookingDetail)
      const option = [...Constant.EQUIPMENTS, ...this.priceOptions].find(
        o => o.bring_equipment === get(bookingDetail, 'bring_equipment', false)
        )
        
        const optionText = option
        ? `(+${option.value} ${this.$t('alias.thb')}) ` +
        this.$t(`equipments.${option.text}`)
        : ``
        
        const result = { ...option, text: optionText }
        console.log("getDefaultEquipmentOption", result)
      return result
    }
  },
  computed: {
    optionEquipments: function() {
      return [...Constant.EQUIPMENTS, ...this.priceOptions].map(option => {
        return {
          ...option,
          text: `(+${option.value} ${this.$t('alias.thb')}) ` + this.$t(`equipments.${option.text}`)
        }
      })
    },
    isSelectBringEquipment: function() {
      return !isEmpty(get(this.bookingForm, 'equipment', null))
    },
    isUserSelectBringEquipmentOption: function() {
      return get(this.bookingForm, 'equipment.bring_equipment', false) // Selected value
    },
    isEquipmentProvideByMaid: function() {
      /**
       * Return true if maid can bring equipment, otherwise return false
       */
      return get(this.bookingForm, 'maid.bring_equipment', false)
    },
    equipmentAmountCharge: function() {
      return get(this.bookingForm, 'equipment.value', 0)
    },
    isMultiPackageApprove: function(){
      if(!this.bookingForm || !this.bookingForm.payment) return false;
      console.log("isMultiPackageApprove",this.bookingForm.payment.product_detail === 'MULTI_PACKAGE',this.bookingForm.bookingCalendars.filter(
          (timeSlot) => timeSlot.status == 'APPROVED'
        ))
      return this.bookingForm.payment.product_detail == 'MULTI_PACKAGE' && this.bookingForm.bookingCalendars.filter( (timeSlot) => timeSlot.status == 'APPROVED').length > 0
    },
    hasEquipmentError: function() {
      if(this.isMultiPackageApprove) return false;

      // console.log("this.isSelectBringEquipment",this.isSelectBringEquipment);
      if (!this.isSelectBringEquipment || get(this.bookingForm, 'method') === 0 || isEmpty(get(this.bookingForm, 'maid'))) {

        return false
      }
      if (!this.isEquipmentProvideByMaid && this.isUserSelectBringEquipmentOption) {

        return true
      }

      return false
    },
    equipmentError: function () {
      if (this.hasEquipmentError) {
        return this.$t(`warning.this_maid_does_not_carry_additional_equipment`)
      }
      return ``
    }
  }
}