<template>
  <div>
  <div class="maid-detail">
    <div class="maid-detail-img mr-3" @click="showMaidDetail" style="cursor: pointer">
      <MaidAvatar :url="detail.user.image_url" :level="detail.level" size="xl" />
    </div>
    <div class="maid-detail-info w-100">
      <div class="name" @click="showMaidDetail" style="cursor: pointer; font-size: 16px">
        {{ detail.user.nick_name ? `"${detail.user.nick_name}" ` : ``
        }}{{ `${detail.user.first_name} ${detail.user.last_name}` }}
      </div>
      <div class="rating d-flex align-items-center flex-wrap">
        <div class="mr-1 text-nowrap">
          {{ detail.rating ? parseFloat(detail.rating).toFixed(1) : $t('alias.no_rating') }}
        </div>
        <star-rating
          :read-only="true"
          :rating="parseInt(this.detail.rating) > 0 ? 1 : 0"
          :max-rating="1"
          :round-start-rating="true"
          :show-rating="false"
          :star-size="16"
          class="mt-n1"
        >
        </star-rating>
        <div class="ml-1 mr-2 text-nowrap">
          {{ detail.no_of_reviews ? detail.no_of_reviews : 0 }}
          {{ $t('alias.reviews') }}
        </div>
        <div class="text-nowrap" v-b-tooltip.hover :title="$t('form_label.repeat_rate')">
          <img src="./../assets/images/heart-icon-jst.png" alt="heart icon" width="16px" style="margin-top: -2.5px;"/>
                {{ repeatRate }} %
          <br />
        </div>
        <!-- <div class="d-flex">
          <div v-if="detail.availability_status == 'LIMITED_AVAILABILITY'" class="limited">
            {{ this.$t('modal.maid_detail.limited_availability') }}
          </div>
          <div  v-else-if="detail.availability_status == 'LOT_OF_AVAILABILITY'" class="a_lot">
            {{ this.$t('modal.maid_detail.a_lot_of_availability') }}
          </div>
        </div>         -->
      </div>
      <b-button
        :variant="disableButton? 'outline-dark mt-2': 'outline-primary mt-2'"
        style="width: 134px"
        :disabled="disableButton"
        @click="onClick"
        v-if="hasButton"
        >{{ buttonText }}</b-button
      >
    </div>
  </div>
  <!-- <div class="mt-2" v-show="isOutOfRadius">
    <span class="text-danger text-nowrap mt-2" v-html="$t('warning.maid_out_of_radius')"></span>
  </div> -->
  </div>
</template>

<script>
import MaidAvatar from './MaidAvatar'
import StarRating from 'vue-star-rating'
import { createNamespacedHelpers } from 'vuex'
const MaidStore = createNamespacedHelpers('maid')
export default {
  components: { StarRating, MaidAvatar },
  props: {
    url: String,
    detail: Object,
    hideAddress: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
    isOutOfRadius: {
      type: Boolean,
      default: false,
    },
    buttonText: String,
  },

  methods: {
    showMaidDetail() {
      // console.log('maid', detail.user.image_url)
      this.setShowMaidModal(this.detail)
      this.$root.$emit('bv::show::modal', 'maid-modal')
    },
    ...MaidStore.mapActions(['setShowMaidModal']),
    onClick() {
      this.$emit('onClickButton')
    },
  },
  computed: {
    avatarSize() {
      return this.hasButton ? `xl` : `md`
    },
    repeatRate() {
      const rate = Math.ceil(this.detail.repeat_rate)
      return rate > 0 ? rate : 0
    }
  },
}
</script>

<style scoped>
.limited {
  background-color: #fff4e2;
  color: #ffb236;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.a_lot {
  background-color: #eeffe6;
  color: #4cb537;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
